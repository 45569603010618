import React from "react"
import PropTypes from "prop-types"
import { Container, Col, Row } from "react-bootstrap"

import bannerImg from '../assets/images/bkgd-cats.jpg'

const Banner = ({ title, titleColor, subTitle, subTitleColor, image }) => (
    <section id="banner" style={{background: `url(${image}) center center no-repeat`, backgroundSize: `cover`}}>
        <Container>
            <Row>
                <Col xs="8" sm="8" md="8" lg="8">
                    <h1 style={{color: `${titleColor}`}}>{title}</h1>
                    <h3 style={{color: `${subTitleColor}`}}>{subTitle}</h3>
                </Col>
            </Row>
        </Container>
    </section>
)

Banner.propTypes = {
    title: PropTypes.string.isRequired,
    titleColor: PropTypes.string,
    subTitle: PropTypes.string.isRequired,
    subTitleColor: PropTypes.string,
    image: PropTypes.string.isRequired
}

Banner.defaultProps = {
    title: ``,
    titleColor: `#134978`,
    subTitle: ``,
    subTitleColor: `#222222`,
    image: `${bannerImg}`
}

export default Banner
