import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import { ContactForm, Name, Email } from '../components/Forms'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Seo from '../components/seo'
import ReCAPTCHA from 'react-google-recaptcha'

import ContactSeoImage from '../assets/images/studio-01.jpg'

const recaptchaRef = React.createRef()

class Contact extends React.Component {
    constructor(props) {
        super(props)

        this.handleUpdate = this.handleUpdate.bind(this)

        this.state = {
            name: ``,
            email: ``,
            phone_number: ``,
            error: ``,
            loading: false,
        }
    }
    
    handleUpdate = event => {
        if (event.target.name === 'name') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'email') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'subject') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'phone_number') {
            this.setState({
                [event.target.name]: event.target.value.replace(/\D/g, ''),
                error: '',
            })
        }
        this.setState({
            [event.target.name]: event.target.value,
            error: '',
        })
    }

    onChange(value) {
        console.log('Captcha Value:', value);
    }

    render() {
        return (
            <StaticQuery
            query={graphql`
                query ContactMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout page='contact'>
                    <Seo title={`Contact Us | ${data.site.siteMetadata.title}`} description={data.site.siteMetadata.description} image={ContactSeoImage} keywords={[``]} />
                    <Banner title='Contact Us' subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' />
                    <Container>
                        <Row>
                            <Col xs='12' sm='12' md='12' lg='12'>
                                <section id='contactForm' className='content'>
                                    <ContactForm title='Connect with Us Today!' subtitle='Feel free to reach out with more ways to give with the form below or see our contact information for more ways to get in touch.' error={this.state.error}>
                                        <Col xs='12' sm='12' md='12' lg='12'>
                                            <Name handleUpdate={this.handleUpdate} name={this.state.name} autoComplete='on' />
                                            <Email handleUpdate={this.handleUpdate} email={this.state.email} autoComplete='on' />
                                            <fieldset>
                                                <textarea name='message' placeholder='Type your message here...' required></textarea>
                                            </fieldset>
                                        </Col>
                                        <ReCAPTCHA ref={recaptchaRef} sitekey={data.site.siteMetadata.google.googleReCaptchaKey} onChange={this.onChange} />
                                        <div className='spacer20'></div>
                                        <button type='submit' className='btn btn-primary center-block' disabled={this.state.loading}>
                                        {this.state.loading ? null : 'Send'}
                                        {this.state.loading && (
                                            <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />
                                        )}
                                        </button>
                                    </ContactForm>
                                </section>
                            </Col>
                        </Row>
                    </Container>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default Contact