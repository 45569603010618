import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function ContactForm({ children, title, subtitle, error }) {
    return (
        <Container>
            <Row>
                <form id='contact' className='card-body' action='https://formspree.io/f/xbjqlzdo' method='POST'>
                    <Col xs='12' sm='12' md='12' lg='12'>
                        <h2>{title}</h2>
                        <p>{subtitle}</p>
                    </Col>
                    {error && (
                        <p className='text-danger'>
                            {error.message ? error.message : error}
                        </p>
                    )}
                    {children}
                </form>
            </Row>
        </Container>
    )
}

export function ConsentForm({ children, title, subtitle, submit, error }) {
    return (
        <Container>
            <Row>
                <form id='consent' className='card-body' onSubmit={submit} method='POST'>
                    <Col xs='12' sm='12' md='12' lg='12'>
                        <h2>{title}</h2>
                        <p>{subtitle}</p>
                    </Col>
                    {error && (
                        <p className='text-danger'>
                            {error.message ? error.message : error}
                        </p>
                    )}
                    {children}
                </form>
            </Row>
        </Container>
    )
}

export function Name({ handleUpdate, name, autoComplete }) {
    return (
        <fieldset>
            <FontAwesomeIcon className='formIcon' icon='user' />
            <input
                onChange={handleUpdate}
                name='name'
                type='text'
                value={name}
                className='form-control'
                autoComplete={autoComplete}
                id='enterFirstName'
                aria-describedby='nameHelp'
                placeholder='Enter your name...'
                required
            />
        </fieldset>
    )
}

export function Email({ handleUpdate, email, autoComplete }) {
    return (
        <fieldset>
            <FontAwesomeIcon className='formIcon' icon='at' />
            <input
                onChange={handleUpdate}
                name='email'
                type='email'
                value={email}
                className='form-control'
                autoComplete={autoComplete}
                id='enterEmailAddress'
                aria-describedby='emailHelp'
                placeholder='your@email.com'
                required
            />
        </fieldset>
    )
}

export function Password({ handleUpdate, password, autoComplete }) {
    return (
        <div className='form-group'>
            <label htmlFor='enterPassword'>Password</label>
            <input
                onChange={handleUpdate}
                autoComplete={autoComplete}
                name='password'
                value={password}
                type='password'
                className='form-control'
                placeholder='Password'
                id='enterPassword'
            />
        </div>
    )
}

export function ConfirmationCode({ handleUpdate, auth_code, autoComplete }) {
    return (
        <div className='form-group'>
            <label htmlFor='enterCode'>Confirmation Code</label>
            <input
                onChange={handleUpdate}
                autoComplete={autoComplete}
                name='auth_code'
                value={auth_code}
                type='text'
                className='form-control'
                placeholder='######'
                id='enterCode'
            />
        </div>
    )
}